import React, { useState, useEffect } from 'react';
import logo from '../img/logo-nobg.png'
import { useLocation } from 'react-router-dom';
import { Container, Snackbar, Alert, DialogContent, DialogTitle, Dialog, Typography, Checkbox, Box, TextField, Button, Grid, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import axios from 'axios';
import { QRCodeCanvas } from "qrcode.react";

function CheckoutPage() {
  const location = useLocation();
  const { course } = location.state || {};
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [cryptoType, setCryptoType] = useState("");
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const url = 'https://astucefx.herokuapp.com/api';
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });

  const config = {
    public_key: 'FLWPUBK-f0b5c6e68e8ba8fca3e36f0b259b0004-X',
    tx_ref: Date.now(),
    amount: course.price,
    currency: 'USD',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: email,
      name: fullName,
    },
    customizations: {
      title: course.title,
      logo: logo,
    },
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleFlutterPayment = useFlutterwave(config);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setSnackbar({ open: true, message: "❌ Please enter a valid email address.", severity: "error" });
      return;
    }

    if (fullName === "" || email === "") {
      alert("Please fill out all fields correctly.");
      setSnackbar({ open: true, message: "❌ Please fill out all fields correctly.", severity: "error" });
      return;
    }
    if (paymentMethod === 'card') {
      setSnackbar({ open: true, message: "❌ Card payment isnt availble for the moment, kindly use Crypto", severity: "error" });
      // handleFlutterPayment({
      //   callback: (response) => {
      //     console.log(response);
      //     closePaymentModal()
      //   },
      //   onClose: () => { },
      // });
    } else if (paymentMethod === "crypto") {
      setLoading(true);
      try {
        const { data } = await axios.post(`${url}/create-crypto-transaction`, {
          amount: course.price,
          currency: cryptoType,
          buyer_email: email,
        });

        if (data.success) {
          setPaymentDetails({
            txn_id: data.txn_id,
            amount: data.amount,
            address: data.address,
            status_url: data.status_url,
          });

          setOpen(true); // Open the pop-up
        } else {
          setSnackbar({ open: true, message: `Error creating transaction:  ${data.error}`, severity: "error" });
        }
      } catch (error) {
        console.error("CoinPayments error:", error);
        setSnackbar({ open: true, message: "❌ Failed to create transaction.", severity: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const verifyPayment = async () => {
    try {
      const { data } = await axios.get(`${url}/verify-transaction/${paymentDetails.txn_id}`);

      if (data.success) {
        setStatus({
          status: data.status,
          status_text: data.status_text,
          received: data.received,
        });

        if (data.status === 1 || data.status === 100) {
          setSnackbar({ open: true, message: "✅ Payment confirmed!", severity: "success" });
          const response = await axios.post('https://astucefx.herokuapp.com/api/register', {
            name: fullName,
            email: email,
            pdf: course.features[2].available,
          });
          setTimeout(() => {
            setOpen(false);
            window.location.href = "http://www.astucefx.com/"; // Redirect to main site
          }, 2000);
        } else if (data.status === 0) {
          setSnackbar({ open: true, message: "⏳ Payment is still pending...", severity: "warning" });
          console.log("⏳ Payment is still pending...");
        } else {
          setSnackbar({ open: true, message: "❌ Payment failed!", severity: "error" });
        }
      } else {
        alert("⚠️ Error verifying payment: " + data.error);
      }
    } catch (error) {
      console.log("Verification error:", error);
      alert("Failed to verify payment.");
    }
  };

  useEffect(() => {
    if (open && paymentDetails?.txn_id) {
      const interval = setInterval(() => {
        verifyPayment();
      }, 10000); // Check every 10 seconds

      return () => clearInterval(interval); // Clean up when closing
    }
  }, [open, paymentDetails]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box sx={{ backgroundColor: '#000', color: '#fff', padding: 4, minHeight: '100vh' }}>
      <Container sx={{ maxWidth: 600, margin: 'auto' }}>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Checkout
        </Typography>
        {course && (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
              {course.title}
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', color: '#d4af37' }}>
              ${course.price}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'grey.500', marginY: 2 }}></Box>
            {course.features.map((feature, index) => (
              <Box key={index} display="flex" alignItems="center" justifyContent="flex-start" mb={1}>
                {feature.available ? (
                  <CheckCircleOutlineIcon sx={{ color: '#d4af37', marginRight: 1 }} />
                ) : (
                  <CancelOutlinedIcon sx={{ color: 'grey', marginRight: 1 }} />
                )}
                <Typography variant="body1">{feature.name}</Typography>
              </Box>
            ))}
          </Box>
        )}
        <form onSubmit={handleSubmit}>

          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            InputProps={{
              style: { color: 'white' },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d4af37',
                },
                '&:hover fieldset': {
                  borderColor: '#d4af37',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d4af37',
                },
              },
            }}
          />
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: { color: 'white' },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d4af37',
                },
                '&:hover fieldset': {
                  borderColor: '#d4af37',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d4af37',
                },
              },
            }}
          />
          <FormControl component="fieldset" sx={{ marginY: 2 }}>
            <FormLabel component="legend" sx={{ color: 'white' }}>Payment Method</FormLabel>
            <RadioGroup
              row
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              sx={{ justifyContent: 'center' }}
            >
              <FormControlLabel value="card" control={<Radio sx={{ color: '#d4af37' }} />} label="Card" />
              <FormControlLabel value="crypto" control={<Radio sx={{ color: '#d4af37' }} />} label="Crypto" />
            </RadioGroup>
          </FormControl>
          {paymentMethod === "crypto" && (
            <Box sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "#D4AF37", mb: 1 }}>Select Crypto Type</FormLabel>
              <RadioGroup
                row
                value={cryptoType}
                onChange={(e) => setCryptoType(e.target.value)}
              >
                {["USDT.TRC20", "USDT.ERC20", "USDC.ERC20", "BTC"].map((crypto) => (
                  <FormControlLabel
                    key={crypto}
                    value={crypto}
                    control={
                      <Radio
                        sx={{
                          color: "#D4AF37",
                          "&.Mui-checked": { color: "#D4AF37" },
                        }}
                      />
                    }
                    label={crypto.replace(".", " ")}
                  />
                ))}
              </RadioGroup>
            </Box>
          )}
          <Button
            type="submit"
            variant="outlined"
            fullWidth
            sx={{
              marginTop: 2,
              borderColor: '#d4af37',
              color: '#d4af37',
              '&:hover': {
                borderColor: '#d4af37',
                backgroundColor: 'rgba(212, 175, 55, 0.1)',
              }
            }}
          >
            Submit Payment
          </Button>
        </form>
      </Container>
      {/* ✅ Payment Details Pop-up */}
      <Dialog open={open} onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}>
        <DialogTitle sx={{ bgcolor: "black", color: "#D4AF37" }}>Payment Details</DialogTitle>
        <DialogContent sx={{ bgcolor: "black", color: "white", textAlign: "center" }}>
          {paymentDetails && (
            <>
              <Typography variant="h6" sx={{ color: "#D4AF37" }}>Scan QR Code to Pay</Typography>
              <QRCodeCanvas value={paymentDetails.address} size={150} fgColor="#D4AF37" bgColor="black" />

              <Typography variant="body1" sx={{ mt: 2 }}>
                <strong>Amount:</strong> {paymentDetails.amount} {cryptoType}
              </Typography>
              <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                <strong>Address:</strong> {paymentDetails.address}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Waiting for payment...
              </Typography>
              <CircularProgress size={30} sx={{ color: "#D4AF37", mt: 2 }} />
            </>
          )}
        </DialogContent>
      </Dialog>
      {/* ✅ Snackbar Notification */}
      <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default CheckoutPage;
